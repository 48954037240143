import {Row} from "react-bootstrap";
import React from "react";
import Bild from "./Bild";
import Text from "./Text";
import Footer from "./Footer";

export default function Home () {
    document.title = 'zChakarov - Startseite';

    return (
        <div className='test'>
            <div className='container'>
                <Row className='blog justify-content-md-around align-items-center'>
                    <Text dir="bottom" speed="53"  sm='12' md='6' lg='5' name="Hallo"/>
                    <Text dir="bottom" speed="13"  sm='12' md='6' lg='5' text="Mein Name ist Zlatin Chakarov. Schon als kleiner Junge war mein Traum, coole interaktive Webseiten zu erstellen. Zur Zeit benutze ich die Site, um meine ReactJs Kenntisse auszuprobieren." />
                </Row>
                <Row className='blog justify-content-md-around align-items-center'>
                    <Bild dir="bottom" speed="14" sm='12' md='4' lg='3' image='image.webp' />
                    <Bild dir="top" speed="18" sm='12' md='5' lg='4' image='image4.webp' />
                    <Bild dir="bottom" speed="33" mode='landscape' sm='12' md='5' lg='3' image='image2.webp' />
                </Row>
                <Row className='blog justify-content-md-between align-items-start'>
                    <Text dir="bottom" speed="12"  sm='12' md='4' lg="4" name="Fotografie" text="das beste Hobby der Welt"/>
                    <Bild dir="bottom" speed="57" sm='12' md='6' lg='4' image='image5.webp' />
                    <Bild dir="top" speed="34" mode='landscape' sm='12' md='5' lg='3' image='image6.webp' />
                </Row>
                <Row className='blog justify-content-md-around align-items-start'>
                    <Bild dir="top" speed="17" sm='12' md='6' lg='5' image='image8.webp' />
                    <Bild dir="bottom" speed="13" mode='portrait' sm='12' md='5' lg='4' image='image9.webp' />
                    <Bild dir="bottom" speed="62" sm='12' md='4' lg='4' image='image7.webp' />

                </Row>
                <Row className='blog justify-content-md-center align-items-start'>
                    <Bild dir="bottom" speed="25" mode='landscape' sm='12' md='5' lg='5' image='image10.webp' />
                </Row>
            </div>
            <Footer />

        </div>

    )
};
