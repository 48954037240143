import React, {Component} from "react";
import jQuery from "jquery";
import {Col, Image, Nav, Navbar, Row} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";

export default class Header extends Component {
    componentDidMount() {
        const isInViewport = (elem) => {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        jQuery(document).on('click', '.navbar-collapse a, .logo a', function (e) {
            jQuery('.navbar-toggler').addClass('collapsed').attr('aria-expanded', false);
            jQuery('.navbar-collapse').removeClass('show');
        });
        jQuery( 'button.navbar-toggler').on('click', function (e) {
            e.stopPropagation();
            if(jQuery(this).hasClass('collapsed')) {
                jQuery('.navbar-collapse').addClass('show');
                jQuery('body').addClass('overflowhidden');
                jQuery('button.navbar-toggler').removeClass('collapsed');
            }
            else {
                jQuery('.navbar-collapse').removeClass('show');
                jQuery('button.navbar-toggler').addClass('collapsed');
                jQuery('body').removeClass('overflowhidden');
            }
        });
        jQuery('.parallax').each(function (index) {
            let parallax = jQuery(this);

            if (isInViewport(this) && !(jQuery(this).hasClass('el_in_viewport'))) {
                setInterval(
                    function () {
                        parallax.addClass('el_in_viewport');
                    }, 900
                );
            }
            else {
                parallax.removeClass('el_in_viewport');
            }
        });
        if ( window.location.pathname === '/' ){
            jQuery('.logo a').css('pointer-events', 'none');
        }
        else {
            jQuery('.logo a').css('pointer-events', 'auto');
        }



        jQuery(window).scroll(function () {
            jQuery('.parallax').each(function () {
                if (isInViewport(this) && !(jQuery(this).hasClass('el_in_viewport'))) {
                    jQuery(this).addClass('el_in_viewport');
                }
            });
            if ((jQuery(window).width()>=1024)) {
                jQuery(".parallax--element").each(function (index, el) {
                    if (jQuery(this).attr("data-dir") === "top") {
                        jQuery(this).css({
                            "top": ((jQuery(window).scrollTop() * jQuery(this).attr('data-speed')) / 250) + "px",
                        });
                    } else if (jQuery(el).attr("data-dir") === "bottom") {
                        jQuery(el).css({
                            "bottom": ((jQuery(window).scrollTop() * jQuery(this).attr('data-speed')) / 250) + "px",
                        });
                    }
                });
            }
        });
    }
    componentDidUpdate() {
        const isInViewport = (elem) => {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };
        jQuery('.parallax').each(function () {
            if (isInViewport(this) && !(jQuery(this).hasClass('el_in_viewport'))) {
                var parallax = jQuery(this);
                setTimeout(
                    function () {
                        parallax.addClass('el_in_viewport');
                    }, 900
                );

            }
        });
        if ( window.location.pathname === '/' ){
            jQuery('.logo a').css('pointer-events', 'none');
        }
        else {
            jQuery('.logo a').css('pointer-events', 'auto');
        }
    }
    render() {
        return (
            <header>
                <div className="container">
                    <Row className='justify-content-between align-items-center'>
                        <Col xs={6}>
                            <div className='logo'>
                                <Link to='/'><Image width='36' height='57' alt="zChakarov" src='https://zchakarov.de/logo.png'/></Link>
                            </div>
                        </Col>
                        <Col className='element' xs={6}>
                            <Navbar expand="xxl" sticky="bottom">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" >
                                    <span className="line"/>
                                    <span className="line"/>
                                    <span className="line"/>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav fill justify>
                                        <NavLink to='/skills' className={({ isActive }) =>
                                            isActive ? 'active-link' : undefined
                                            }>Skills
                                        </NavLink>
                                        <NavLink to='/portfolio' className={({ isActive }) =>
                                            isActive ? 'active-link' : undefined
                                            }>Portfolio</NavLink>
                                        <NavLink to='/kontakt' className={({ isActive }) =>
                                            isActive ? 'active-link' : undefined
                                            }>Kontakt</NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </div>
            </header>
        );
    }
};
