import {Col, Container, ProgressBar, Row} from "react-bootstrap";
import React from "react";
import Footer from "./Footer";

export default function Skills() {
    document.title = 'zChakarov - Skills';

    return(
        <div className='test'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={12} lg={6}>
                        <div>
                            <h3 className="skill-name">HTML5</h3>
                            <ProgressBar variant="warning" now={70} />
                            <h3 className="skill-name">SASS</h3>
                            <ProgressBar variant="warning" now={70} />
                            <h3 className="skill-name">Bootstrap</h3>
                            <ProgressBar variant="warning" now={70} />
                            <h3 className="skill-name">jQuery</h3>
                            <ProgressBar variant="warning" now={60} />
                            <h3 className="skill-name">MySQL</h3>
                            <ProgressBar variant="warning" now={60} />
                            <h3 className="skill-name">PHP</h3>
                            <ProgressBar variant="warning" now={40} />
                            <h3 className="skill-name">Fluid</h3>
                            <ProgressBar variant="warning" now={40} />
                            <h3 className="skill-name">ReactJS</h3>
                            <ProgressBar variant="warning" now={10} />
                            <h3 className="skill-name">NodeJS</h3>
                            <ProgressBar variant="warning" now={2.5} />
                            <h3 className="skill-name">Express</h3>
                            <ProgressBar variant="warning" now={2.5} />
                            <h3 className="skill-name">TypeScript</h3>
                            <ProgressBar variant="warning" now={1} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />

        </div>
    )

};
