import React, {useState, useRef, useEffect} from "react";
import {Col, Image, Modal} from "react-bootstrap";

export default function Bild(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const inputRef = useRef();


    return (
        <Col md={props.md} lg={props.lg} className="background">
                       <div ref={inputRef} className={`lazy parallax parallax--element parallax--image ${props.mode === 'portrait' ? 'parallax--element__portrait' : ''} ${props.mode === 'landscape' ? 'parallax--element__landscape' : ''}`} data-dir={props.dir} data-speed={props.speed} onClick={handleShow} style={{
                backgroundImage: "url('" + props.image + "')",

            }} >
                {props.name?<h1>{props.name}</h1>:''}
            </div>

        
        </Col>
    );
};
