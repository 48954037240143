import React from "react";
import Project from "./Project";
import {Container} from "react-bootstrap";
import Footer from "./Footer";

export default function Portfolio () {
    document.title = 'zChakarov - Portfolio';

    return (
        <div className="test">
            <Container>
                <div className="projects">
                    <Project name="Nadia // Hochzeit & Taufaccessoires" text="ReactJs und Wordpress REST API" image='nadia.webp' link="https://nadia-taufe.com/" />

                </div>

            </Container>
            <Footer/>
        </div>

)
};
