import 'react-app-polyfill/ie11';
import React from 'react';
import {Routes, Route, Link, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './main.js';
import Header from "./components/Header";
import Header2 from "./components/Header2";
import Home from "./components/Home";
import Skills from "./components/Skills"
import Error from "./components/Error";
import Contact from "./components/Contact";
import "./styles.css";
import CookieConsent from "react-cookie-consent";
import Datenschutz from "./components/Datenschutz";
import Portfolio from "./components/Portfolio";

function App () {
    const location = useLocation();

    return (
    <div className="body-container">
        <Header/>
        <CookieConsent
            location="bottom"
            buttonText="Einverstanden"
            cookieName="Cookies"
            style={{ background: "#00CCCB" }}
            buttonStyle={{background:"#fcfce8", color: "#111111", fontSize: "13px" }}
            buttonClasses = "btn"

            expires={150}
        >
            Diese Internetseite verwendet Cookies <Link className="btn mx-md-4" to="datenschutz">Mehr erfahren </Link>

        </CookieConsent>
        <TransitionGroup className="transition-group">
            <CSSTransition
                key={location.key}
                classNames="fade"
                transitionAppear={true}
                transitionEnter={true}
                transitionLeave={true}
                timeout={{
                    appear: 900,
                    enter: 900,
                    exit: 900,
                }}
                unmountOnExit={true}
                appear
            >
                <Routes location={location} className='container'>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/kontakt" element={<Contact/>}/>
                    <Route path="/portfolio" element={<Portfolio/>}/>
                    <Route path="/skills" element={<Skills/>}/>
                    <Route path="/datenschutz" element={<Datenschutz/>}/>
                    <Route path="*" element={<Error/>}/>

                </Routes>
            </CSSTransition>
        </TransitionGroup>

    </div>
    )
};
export default App;

/*
*
*
*
* */
