import React, {useEffect, useState} from "react";

export default function TextSplit(props) {

    const [newText, setNewText] = useState([]);
    useEffect(() => {
        setNewText(props.text.split(' '));
    }, []);

    return (
        newText.map((el, i) => {
            return (
                <span key={i} data-value ={el} className="word">
                    {el}&nbsp;
                </span>
            )
        }
)
    )
}
