import React from "react";
import axios from "axios";
import jQuery from "jquery";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import Footer from "./Footer";
export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            tempname: '',
            email: '',
            message: '',
            issubmitted: false
        }
    }

    componentDidMount() {
        document.title = 'zChakarov - Kontakt';

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        document.title = 'zChakarov - Kontakt';

    }

    handleSubmit(e){
        e.preventDefault();
        axios({
            method: "POST",
            url:"https://zchakarov.de/send.php",
            data:  this.state
        }).then((response)=>{
            if (response.data.status === 'success') {
                this.setState({issubmitted: true});
                this.setState({tempname: this.state.name});
                this.setState({name: "", email: "", message: ""})
                jQuery('input, textarea').each(function (i) {
                    jQuery(this).removeClass('leer');
                    jQuery(this).parent().prev('label').find('.required-error').remove();

                });
                jQuery('.contact--message').addClass('contact--message--show');

            } else if (response.data.status === 'fail') {
                this.setState({issubmitted: false});
                jQuery('input, textarea').each(function (i) {
                    if(!jQuery(this).val() && !jQuery(this).hasClass('leer')) {
                        jQuery(this).addClass('leer');
                        jQuery(this).parent().prev('label').append('<span class="required-error"> ist erforderlich</span>')}
                    if(jQuery(this).val() && jQuery(this).hasClass('leer')) {
                        jQuery(this).removeClass('leer');
                        jQuery(this).parent().prev('label').find('.required-error').remove();
                    }
                })
            }
        })

    }

    render() {
        return(
            <div className="test">
                <Container  className='contact'>
                    {this.state.issubmitted == false ?
                        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">

                            <Row className='justify-content-center align-items-center contact-input'>
                                <Col className='contact-col' lg={4} xs={12}>
                                    <div className="form-group mx-2">
                                        <label htmlFor="name">Name</label>
                                        <span className='form-group-span'>
                                                    <input type="text" required className="form-control p-2" id="name"
                                                           value={this.state.name}
                                                           onChange={this.onNameChange.bind(this)}/>
                                                </span>
                                    </div>
                                    <div className="form-group mx-2">
                                        <label htmlFor="email">E-Mail-Adresse</label>
                                        <span className='form-group-span'>
                                                <input type="email" required className="form-control p-2" id="email"
                                                       aria-describedby="emailHelp" value={this.state.email}
                                                       onChange={this.onEmailChange.bind(this)}/>
                                            </span>
                                    </div>
                                    <div className="form-group mx-2">
                                        <label htmlFor="message">Nachricht</label>
                                        <span className='form-group-span'>
                                                    <textarea className="form-control p-2" rows="5" id="message"
                                                              value={this.state.message}
                                                              onChange={this.onMessageChange.bind(this)}/>
                                                </span>
                                    </div>
                                    <div className="form-group mx-2">
                                        <button type="submit" className="btn">Abschicken</button>

                                    </div>

                                </Col>
                            </Row>
                        </form> :
                        <div>
                            <div>
                                <div className='content-container contact'>
                                    <Container fluid="xl" className="animation animation--top">
                                        <Row>
                                            <Col lg={12}>
                                                <div
                                                    className={this.state.issubmitted ? 'contact--message contact--message--show' : 'contact--message'}>
                                                    <h3>{this.state.tempname}, Vielen Dank für Ihre
                                                        Nachricht.</h3>
                                                    <Link to="/"><p
                                                        className="link m-0 align-self-center">Zurück zur Startseite</p></Link>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </div>


                            </div>

                        </div>
                    }
                </Container>
                <Footer/>
            </div>
        );
    }

    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}
