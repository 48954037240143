import React from "react";
import TextSplit from "./TextSplit";
import {Row, Col} from "react-bootstrap";
export default function Project (props) {
    return (
        <Row className="project align-items-center">
            <Col lg="6" sm="12" className="project--data">
                <div className="parallax parallax--element parallax--text">
                    <h1>{props.name}</h1>
                    <p>
                        <TextSplit text = {props.text} />
                    </p>
                    <a href={props.link} className='btn' target="_blank">Seite anschauen</a>

                </div>
            </Col>
            <Col lg="4" sm="12" className="project--images parallax parallax--element parallax--image">
                <img className="img-fluid" src={`/portfolios/${props.image}`} width={500} alt={props.name}/>
            </Col>
        </Row>

    )
};
