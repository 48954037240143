import React, {useState} from "react";
import {Col, Row, Image, Modal, Nav} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";

export default function Footer(props) {
    return (
        <div className="footer">
            <p className="m-0">2022 zChakarov</p>
            <Nav className='mx-4' fill justify>
                <NavLink to='/datenschutz' className={({ isActive }) =>
                                            isActive ? 'active-link' : undefined
                                            }>Datenschutz</NavLink>
            </Nav>
        </div>

    );
};
