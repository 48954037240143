import React, {useEffect, useState} from "react";
import TextSplit from "./TextSplit";
import {Col} from "react-bootstrap";

export default function Text (props) {
    const [id, setId] = useState('');
    if(id.length > 0) {

    }
    useEffect(() => {
        if(props.name) {
            setId(props.name.slice(0, 1).toLowerCase() + props.name.replace(/[^a-zA-Z0-9 ]/g, '').slice(-1) + props.dir[0] + '-' +  props.speed)

        }

        }, []
    );

    return (
        <Col md={props.md} lg={props.lg}>
            <div className="parallax parallax--element parallax--text" id={id} data-dir={props.dir} data-speed={props.speed}>
                {props.name && <h1>{props.name}</h1> }
                {props.text &&<p>
                    <TextSplit text = {props.text} />
                </p> }
            </div>
        </Col>
    )
};
